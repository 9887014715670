.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.navigation-button-container {
  justify-self: center;
  background-color: rgb(255, 255, 255);
  width: fit-content;
  padding: 8px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  overflow: auto;
  align-items: center;
  position: relative;
}

.navigation-button,
.connect-wallet-button {
  border-radius: 14px;
  font-weight: 600;
  justify-content: center;
  border: none;
  padding: 5px 20px;
}

.navigation-button.active {
  background-color: rgb(247, 248, 250);
  color: rgb(0, 0, 0);
}

.navigation-button.inactive,
.navigation-button.inactive:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(86, 90, 105);
}

.connect-wallet-button {
  font-weight: 500;
  background-color: #212529 !important;
  border: 1px solid rgb(253, 234, 241);
  color: white !important;
  /* outline: 2px solid white; */
box-shadow: 0px 0px 32px 0px rgb(232 164 33 / 50%);
border: 1px solid transparent;
  margin: 0 20px;
  position: relative;
}

.brand-logo img {
  width: 45px;
  height: 45px;
  position: relative;
}
