.swap-main-container {
  position: relative;
  max-width: 498px;
  width: 106%;
  border-radius: 24px;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  background: rgb(25, 27, 31);
  border: 1px solid transparent;
  padding: 12px;
  box-shadow: 0px 0px 32px 0px rgba(232, 164, 33, 0.5);
}

.swap-container {
  display: flex;
  justify-content: space-between;
  padding: 6px;
  align-items: center;
  margin-bottom: 10px;
}
.swap-container .btn-setting{
  background-color: transparent;
  border: none;
}

.swap-container h1 {
  color: transparent;
  font-size: 16px;
  background-clip: border-box;
  background: linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #ffecb3 40%, #674d16 78%);
  background-clip: text;
  margin-bottom: 0px;
}

.swap-container svg circle {
  stroke: rgb(178, 150, 78);
}
.swap-container svg path {
  stroke: rgb(178, 150, 78);
}

.swap-amount-input {
  width: 100%;
  font-size: 28px;
  background-color: transparent;
  color: #6e727d;
  padding: 0 20px;
  outline: none !important;
  border: none !important;
}

.swap-input-container {
  display: flex;
  background-color: rgb(0, 0, 0);
  border-radius: 20px;
  margin: 0px 0px 5px 0px;
  border: 1px solid rgba(178, 150, 78, 0.3);
}
.swap-input-container:hover{
  border: 1px solid rgb(178, 150, 78);
}

.quote-button {
  background: rgb(253, 234, 241);
  border: 1px solid rgb(253, 234, 241);
  color: rgb(213, 0, 102);
  padding: 5px 20px;
  outline: none;
  margin: 7px 0;
  border-radius: 15px;
}

.connect-wallet-button {
  font-weight: 500;
  background-color: rgb(253, 234, 241);
  border: 1px solid rgb(253, 234, 241);
  color: rgb(213, 0, 102);
  outline: -5px solid white;
  margin: 24px 20px;
  margin-left: 12px;
}

.select-token {
  font-weight: 500;
  background-color: rgb(253, 234, 241);
  border: 1px solid rgb(253, 234, 241);
  color: rgb(213, 0, 102);
  outline: -5px solid white;
  margin: 24px 20px;
  margin-left: 12px;

}



/* .golden-bridge-sec{
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
  max-width: 498px;
  padding: 12px;
  border-radius: 20px;
  background-color: rgb(130, 70, 229);
}
.golden-sec-content{
  padding-left: 15px;
}
.golden-sec-content h5{

}
.golden-sec-content p{

} */

.curruncy-modal-wrap .balance{
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 14px;
  color: rgb(143, 150, 172);
  position: relative;
  top: -6px;
}
.curruncy-modal-wrap.selected-token div img{
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 5px;
} 
.curruncy-modal-wrap.selected-token div{
  display: flex;
  align-items: center;
}