@font-face {
   font-family: 'Inter custom',sans-serif;
   src: url('./../fonts/Inter-roman.var.woff2');
   font-weight: bold;
}

html,body{
   font-family: 'Inter custom',sans-serif !important;
}
button{
   font-family: 'Inter custom',sans-serif !important;
}
input{
   font-family: 'Inter custom',sans-serif !important;
}

/* global background style */
.backround-radial-gredient{
   width: 100vw;
   height: 100vh;
   transform: unset;
   background-blend-mode: multiply, normal;
   background-image: url('./../assets/images/background.jpg');
   position: fixed;
   left: 0px;
   right: 0px;
}

.select-token{
   width: 100%;
}
.select-token:focus{
   box-shadow: none;
}
.select-token.primary{
   background-color: rgba(173, 150, 78, 0.2);
   color: rgb(173, 150, 78);
   font-size: 16px;
   font-weight: 500;
   margin: 10px 5px;
}
.select-token.primary:hover{
   background-color: rgba(173, 150, 78, 0.5);
}
.btn{
   padding: 16px;
   width: 100%;
   font-weight: 500;
   text-align: center;
   border-radius: 20px;
   outline: none;
   border: 1px solid transparent;
   color: rgb(0, 0, 0);
   text-decoration: none;
   display: flex;
   -webkit-box-pack: center;
   justify-content: center;
   flex-wrap: nowrap;
   -webkit-box-align: center;
   align-items: center;
   cursor: pointer;
   position: relative;
   z-index: 1;
   will-change: transform;
   transition: transform 450ms ease 0s;
   transform: perspective(1px) translateZ(0px);
}
.exchange-wrapper-sec .arrow-exchange.rotateUp svg{
   transform: rotate(180deg);
}
.exchange-wrapper-sec .arrow-exchange{
   padding: 4px;
   border-radius: 12px;
   height: 32px;
   width: 32px;
   position: relative;
   margin-top: -14px;
   margin-bottom: -14px;
   left: calc(50% - 16px);
   background-color: rgb(25, 25, 24);
   border: 4px solid rgb(173, 150, 78);
   z-index: 2;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}
.exchange-wrapper-sec .arrow-exchange svg{
   transition: all .3s ease-in-out;
}
.exchange-wrapper-sec .arrow-exchange svg line{
   stroke: rgb(173, 150, 78);
}
.exchange-wrapper-sec .arrow-exchange svg polyline{
   stroke: rgb(173, 150, 78);
}
.curruncy-modal-wrap button{
   background-color: rgba(162, 140, 87, 0.2);
   border: rgb(173, 150, 78, 0.2);
   opacity: 1;
   box-shadow: rgb(0 0 0 / 8%) 0px 6px 10px;
   color: rgb(255, 255, 255);
   cursor: pointer;
   height: 2.4rem;
   border-radius: 16px;
   outline: none;
   user-select: none;
   border: none;
   font-size: 18px;
   justify-content: center;
   font-weight: 400;
   min-width: 160px;
   display: flex;
   width: initial;
   padding: 2px 7px 2px 10px;
   align-items: center;
   gap: 0px;
   -webkit-box-pack: justify;
   margin: 16px;
   visibility: visible;
   white-space: nowrap;
}
.curruncy-modal-wrap button:hover{
   background-color: rgb(173, 150, 70);
}
.curruncy-modal-wrap button svg{
   margin-left: 3px;
}
.curruncy-modal-wrap.selected-token button{
   min-width: auto;
   background-color: #fff;
   color: rgb(0, 0, 0);
}
.calc-bottom-sec{
   display: none;
   padding: 4px 0px 4px 8px;
   margin: 0px;
   /* display: flex; */
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
   border-radius: 12px;
}
.calc-bottom-sec.activeCollapse{
   background-color: rgb(247, 248, 250);
}
.calc-bottom-sec.activeCollapse .calculating-value button span{
   color: #6e727d;
}
.calc-bottom-sec .loading-section{
   text-align: left;
}
.calc-bottom-sec .loading-section p{
   color: #6e727d;
}
.calculating-value span svg{
   height: 16px;
   width: 16px;
   margin-right: 4px;
   color: rgb(110, 114, 125);
}
.calculating-value button{
   border: none;
   background-color: transparent;
}
.calculating-value button span{
   box-sizing: border-box;
   margin: 0px;
   min-width: 0px;
   font-weight: 500;
   font-size: 13px;
   color: rgb(255, 255, 255);
   font-family: 'Inter custom',sans-serif !important;
}
.calculating-value button span:nth-child(2){
   color: #6e727d;
}
.calculate-perct button{
   border: none;
   border-radius: 8px;
   padding: 2px 9px;
   height: 24px;
   color: rgb(110, 114, 125);
   background-color: rgb(247, 248, 250);
   font-size: 14px;
   font-weight: 500;
   user-select: none;
}
.calculate-perct svg{
   height: 28px;
   width: 28px;
   margin-right: 0px;
   fill: rgb(110, 114, 125);
   cursor: pointer;
   transition: all .4s ease-in-out;
}
.calc-bottom-sec.activeCollapse .calculate-perct span svg{
   transform: rotate(180deg);
}
.calculate-perct button svg{
   height: 16px;
   width: 16px;
   margin-right: 4px;
   fill: rgb(110, 114, 125);
   cursor: pointer;
}


/* Modal styles */
.react-responsive-modal-container{
   padding: 10px;
}
.react-responsive-modal-modal{
   margin: 0px 0px 2rem !important;
   background-color: rgb(25, 27, 31)!important;
   border: 2px solid rgba(178, 150, 78, 0.3);
   box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
   border-radius: 12px;
   padding: 0px !important;
   width: 50vw;
   overflow: hidden auto;
   align-self: center;
   max-width: 420px !important;
   max-height: 80vh;
   min-height: 80vh;
   border-radius: 20px;
   width: 100%;
}
.react-responsive-modal-modal .modal-header{
   border: none;
   padding: 18px;
}
.react-responsive-modal-modal .modal-header h4{
   box-sizing: border-box;
   margin: 0px;
   min-width: 0px;
   font-weight: 500;
   font-size: 16px;
   color: #fff;
}
.react-responsive-modal-modal .modal-footer{
   width: 100%;
   border-radius: 0px 0px 20px 20px;
   padding: 20px;
   background-color: #5c5544;
   border-top: 1px solid rgb(173, 150, 78);
   height: 60px;
   display: flex;
   justify-content: center;
   align-items: center;
   align-items: center;
   position: absolute;
   bottom: 0px;
   z-index: 9;
}
.react-responsive-modal-modal .modal-footer button{
   border: none;
   background-color: transparent;
   padding: 0px;
   box-sizing: border-box;
   margin: 0px;
   min-width: 0px;
   font-weight: 500;
   color: rgb(173, 150, 78);
   display: flex;
   align-items: center;
}
.react-responsive-modal-modal .modal-footer button:hover{
   opacity: 1;
}
.react-responsive-modal-modal .modal-footer button svg{
   width: 16px;
   height: 16px;
   margin-right: 10px;
}
.react-responsive-modal-closeButton svg{
   width: 24px;
   height: 24px;
   fill: #fff;
}
.token-modal-wrapper .form-group{
   padding: 10px 20px 0px;
   margin-bottom: 0px;
}
.token-modal-wrapper .form-group .form-control{
    background: url('./../assets/images/search.svg') 12px center / 20px 20px no-repeat scroll;
    position: relative;
    display: flex;
    padding: 16px 16px 16px 40px;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 56px;
    white-space: nowrap;
    outline: none;
    border-radius: 20px;
    color: #cec6c6;
    border: 1px solid rgba(178, 150, 78, 0.3);
    appearance: none;
    font-size: 16px;
    transition: border 100ms ease 0s;
}
.token-modal-wrapper .form-group .form-control:focus{
   border: 1px solid rgb(173, 150, 78);
   outline: none;
   box-shadow: none;
}
.currency-list{
   padding: 10px 20px;
}
.currency-list ul{
   display: flex;
   flex-wrap: wrap;
   padding: 0px;
   margin: 0px;
}
.currency-list ul li{
   border: 1px solid #5c5544;
   border-radius: 10px;
   display: flex;
   padding: 6px 12px 6px 6px;
   -webkit-box-align: center;
   align-items: center;
   margin: 4px !important;
}
.currency-list ul li:hover{
   cursor: pointer;
   background-color: rgba(173, 150, 78, 0.6);
}
.currency-list ul li img{
   width: 24px;
   height: 24px;
   background: radial-gradient(white 50%, rgba(255, 255, 255, 0) calc(75% + 1px), rgba(255, 255, 255, 0) 100%);
   border-radius: 50%;
   box-shadow: white 0px 0px 1px;
   border: 0px solid rgba(255, 255, 255, 0);
   margin-right: 8px;
}
.divider2{
   width: 100%;
   height: 1px;
   background-color: #5c5544;
}
.currency-scroll-list{
   height: calc(80vh - 250px);
   overflow: auto;
}
.currency-scroll-list ul{
   margin: 0px;
   padding: 0px;
   padding-bottom: 3px;
}
.currency-list ul li span{
   color: #b5b4b4;
   font-size: 14px;
}
.currency-scroll-list ul li{
   list-style-type: none;
   display: flex;
   align-items: center;
   padding: 5px 20px;
   cursor: pointer;
}
.currency-scroll-list ul li:hover{
   background-color: rgba(173, 150, 78, 0.6);
}
.currency-scroll-list ul li:hover .currency-info h5{
   color: #fff;
}
.currency-scroll-list ul li:hover .currency-info p{
   color: rgb(191, 193, 199);
}
.currency-scroll-list .currency-media{
   margin-right: 10px;
}
.currency-scroll-list .currency-media img{
   width: 24px;
   height: 24px;
   background: radial-gradient(white 50%, rgba(255, 255, 255, 0) calc(75% + 1px), rgba(255, 255, 255, 0) 100%);
   border-radius: 50%;
   box-shadow: black 0px 0px 1px;
   border: 0px solid rgba(255, 255, 255, 0);
   max-width: 24px;
   max-height: 24px;
}
.currency-scroll-list .currency-info h5{
   font-size: 15px;
   margin-bottom: 0px;
   color: #cec6c6;
}
.currency-scroll-list .currency-info p{
   box-sizing: border-box;
   margin: 0px;
   min-width: 0px;
   font-weight: 300;
   font-size: 12px;
   color: rgb(110, 114, 125);
}
.swap-result-table{
   padding: 12px;
   border: 1px solid rgba(173, 150, 98, 0.3);
   background-color: rgb(0, 0, 0);
   border-radius: 16px;
   margin: 10px 0px 0px;
}
.swap-result-table .list-tr .divider2{
   margin: 5px 0px;
}
.swap-result-table p{
   box-sizing: border-box;
   margin: 0px;
   min-width: 0px;
   font-weight: 400;
   font-size: 14px;
   color: rgb(110, 114, 125);
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 3px 0px;
}
.swap-result-table p span{
   color: rgb(110, 114, 125);
}
.swap-result-table p span.orange-text{
   color: rgb(255, 143, 0);
}
.swap-collapse{
   height: 0px;
   overflow: hidden;
   transition: all .5s ease;
}
.swap-collapse.show{
   height: 100%;
   overflow: visible;
}

/* Loader */
.loading-section .svgLoader {
   animation: spin 0.5s linear infinite;
   margin: auto;
   width: 13px;
   height: 13px;
   border: 2px solid grey;
 }
 .loading-section .svgLoader path{
   fill: grey;
 }
 @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
 }

.navigation-button-container{
   margin: auto;
   max-width: 498px;
   min-width: 498px;
}
 .exchange-points .navigation-button img{
   width: 20px;
   height: 20px;
   margin-right: 5px;
 }
 .connect-wallet-button{
   margin: 0px 20px;
 }
 .navigation-button-container marquee ul{
   display: flex;
   margin: 0px;
   padding: 0px;
}
.navigation-button-container marquee ul li{
   list-style-type: none;
   cursor: pointer;
}
.navigation-button-container marquee ul li img{
   margin-left: 3px;
   width: 20px;
   height: 20px;
}
.menu-dropdown-right button{
   min-width: 40px;
   border-radius: 16px;
}
.menu-dropdown-right .MuiButtonBase-root img{
   width: 20px;
   margin-right: 6px;
}
.MuiPaper-root{
   min-width: 196px;
   background-color: rgb(247, 248, 250) !important;
   box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
   border: 1px solid rgb(255, 255, 255) !important;
   border-radius: 12px !important;
   padding: 0.5rem !important;
   display: flex;
   flex-direction: column;
   font-size: 16px !important;
}
.MuiPaper-root .MuiList-root .MuiButtonBase-root{
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.MuiPaper-root .MuiList-root .MuiButtonBase-root:last-child:hover{
   background-color: transparent !important;
}
.MuiPaper-root .MuiList-root .MuiButtonBase-root button{
   background: radial-gradient(174.47% 188.91% at 1.84% 0%, rgb(255, 0, 122) 0%, rgb(33, 114, 229) 100%), rgb(237, 238, 242);
   border: none;
   width: 100%;
   padding: 7px;
   border-radius: 13px;
   color: #fff;
   font-family: 'Inter custom',sans-serif;
}
.MuiPaper-root .MuiList-root .MuiButtonBase-root svg{
   fill: grey;
   width: 15px;
   height: 15px;
   margin-left: 5px;
}
button.navigation-button.inactive {
   height: 40px;
   font-weight: 600;
   text-transform: capitalize;
   color: #000;
   font-size: 16px;
}

.currency_icon2{
   width: 20px;
   height: 20px;
   margin-right: 15px;
}
.MuiPaper-root .MuiList-root .MuiButtonBase-root.currency_list_bar2{
   justify-content: flex-start;
}

@media screen and (max-width:767px){
   .swap-main-container{max-width: 95%;}
   .navigation-button-container{
      max-width: 95%;
      min-width: 95%;
   }
   .connect-wallet-button{margin: 0px 10px;}
   .navigation-container .menu-dropdown-right button span{
      font-size: 12px;
   }
   .navigation-container .menu-dropdown-right button{
      padding: 7px 9px;
line-height: 0px;
height: auto;
   }
   .navigation-container .menu-dropdown-right button svg{
      display: none;
   }
}



.loading,.notfound{
   text-align: center;
   margin-top: 10px;
   font-size: 16px;
   font-weight: 500;
   display: block;
   text-transform: capitalize;
   color: #cec6c6;
}
.setting-popup{
   min-width: 20.125rem;
   background-color: rgb(25, 27, 31);
   border:2px solid rgba(178, 150, 78, 0.3);
   box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   font-size: 1rem;
   position: absolute;
   top: 52px;
   right: 20px;
   z-index: 100;
   user-select: none;
   padding: 16px;
}
.setting-popup h2{
   box-sizing: border-box;
   margin: 0px 0px 12px 0px;
   min-width: 0px;
   font-weight: 600;
   font-size: 14px;
   text-align: left;
   color: #fff;
}
.transaction-setting{

}
.transaction-setting .st-field{
   text-align: left;
   margin-bottom: 12px;
}
.transaction-setting .st-field label{
   margin: 0px 0px 8px 0px;
   font-weight: 400;
   color: #cec6c6;
   font-size: 14px;
   text-align: left;
}
.transaction-setting .st-field .st-inbtn{
   display: flex;
   align-items: center;
   width: 100%;
}

.transaction-setting .st-field .st-inbtn .btn{
   margin-right: 8px;
   background-color:rgba(173, 150, 78, 0.8);
   color: rgb(255, 255, 255);
   border-radius:36px;
   font-size: 14px;
   padding: 5px 0px;
   width: auto;
   min-width: 56px;
   border: none;
}
.transaction-setting .st-field .st-inbtn input{
   border: 1px solid rgb(206, 208, 217);
   outline: none;
   background: rgb(247, 248, 250);
   font-size: 14px;
   border-radius: 36px;
   height: 32px;
   width: 100%;
   padding: 5px 25px 5px 15px;
   text-align: right;
   position: relative;
}
.transaction-setting .st-field .tolerance-value{
   position: relative;
}
.transaction-setting .st-field .tolerance-value:after{
   content: '%';
   position: absolute;
   right: 11px;
   top: 6px;
   color: rgb(0, 0, 0);
   font-size: 14px;
}
.transaction-setting .st-field .trs-deadline input{
   max-width: 80px;
   padding: 5px 15px;
}
.transaction-setting .st-field span{
   font-weight: 400;
   font-size: 14px;
   color: #cec6c6;
   margin-left: 8px;
}
.interface-setting .auto-filed{
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 12px;
}
.interface-setting .auto-filed:last-child{
   margin-bottom: 0px;
}
.interface-setting .auto-filed span{
   font-weight: 400;
   color: #cec6c6;
   font-size: 14px;
   text-align: left;
}

/*modal css start */
.connect-wallet-modal .modal-dialog{
   max-width: 420px;
   display: flex;
   align-items: center;
   height: 100%;
   justify-content: center;

}
.connect-wallet-modal .modal-dialog .modal-content{
   background-color: rgb(255, 255, 255);
   border: 1px solid rgb(247, 248, 250);
   box-shadow: rgb(47 128 237 / 5%) 0px 4px 8px 0px;
   border-radius: 20px;
}
.connect-wallet-modal .modal-dialog .modal-content .modal-header{
   border: none;
}
.connect-wallet-modal .modal-dialog .modal-content .modal-header .modal-title{
   font-size: 16px;
   font-weight:600; 
}
.connect-wallet-modal .modal-dialog .modal-content .modal-header .close{
   color: #25292E;
    opacity: 1;
    font-weight: 400;
    font-size: 26px;
}
.connect-wallet-modal .modal-dialog .modal-content .modal-body{
   padding-top: 0px;
   padding-bottom: 0px;
}
.connect-wallet-detail .btn{
   display: flex;
   flex-direction: row;
   -webkit-box-align: center;
   align-items: center;
   -webkit-box-pack: justify;
      justify-content: space-between;
      margin-top:0;
   padding: 1rem;
   outline: none;
   border: 1px solid rgb(206, 208, 217);
   border-radius: 12px;
   background-color: rgb(237, 238, 242);
   margin-bottom: 10px;
   box-shadow: none;
}
.connect-wallet-detail .btn img{
   height: 24px;
   width: 24px;
}
.connect-wallet-detail .btn:not(:disabled):not(.disabled):active{
   background-color: rgb(237, 238, 242);
   border: 1px solid rgb(206, 208, 217);
   color: #000;
   box-shadow: none;
   outline: none;
}
.connect-wallet-detail .btn:last-child{
   margin-bottom: 0px;
}
.connect-wallet-detail .btn:hover{
   cursor: pointer;
   border: 1px solid rgb(232, 0, 111);
}
.connect-wallet-detail .btn h2{
   color: rgb(0, 0, 0);
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 0px;
}
.modal-footer {
   border-top: none;
}
.modal-footer .wallet-footer{
   border: 1px solid rgb(237, 238, 242);
   background-color: rgb(247, 248, 250);
   width: 100%;
   padding: 1rem;
   border-radius: 16px;
}
.modal-footer .wallet-footer p{
   font-weight: 400;
   font-size: 12px;
   color: rgb(0, 0, 0);
   margin-bottom: 0px;
} 
.modal-footer .wallet-footer p a{
   cursor: pointer;
   color: rgb(251, 17, 142);
   stroke: rgb(251, 17, 142);
   font-weight: 500;
   text-decoration: underline;
}

.account-wallet-detail .account-connect{
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 12px;
}
.account-wallet-detail .account-connect h3{
   font-size: 13.2px;
   font-weight: 500;
   color: rgb(110, 114, 125);
   margin-bottom: 0px;
}
.account-wallet-detail .account-connect .connect-btn{
   display: flex;
   align-items: center;
}
.account-wallet-detail .account-connect .connect-btn .btn:last-child{
   margin-right: 0px;
   margin-left: 8px;
}
.account-wallet-detail .account-connect .connect-btn .btn{
   border: 1px solid rgb(246, 221, 232);
   color: rgb(232, 0, 111);
   background-color: transparent;
   border-radius: 12px;
   padding: 4px 6px;
   margin-right: 8px;
   font-size: 13.2px;
   font-weight: 400;
   box-shadow: none!important;
   outline: none;
}
.account-wallet-detail .account-connect .connect-btn .btn:hover{
   border: 1px solid rgb(255, 153, 201);
   text-decoration: underline;
}
account-wallet-detail .account-connect .connect-btn .btn:active,
account-wallet-detail .account-connect .connect-btn .btn:focus{
   outline: none;
   box-shadow: none;
}
.account-wallet-detail .connect-data{
   display: flex;
   align-items: center;
   margin-bottom: 12px;
}
.account-wallet-detail .connect-data img{
   width: 22.9px;
   height: 22.9px;
}
.account-wallet-detail .connect-data p{
   margin-left: 8px;
   margin-bottom: 0px;
   color: #000;
   font-size: 20px;
}
.account-wallet-detail .cn-copy-address{
   display: flex;
   align-items: center;
}
.account-wallet-detail{
   padding: 16px;
   border: 1px solid rgb(206, 208, 217);
   border-radius: 20px;
   margin-bottom: 40px;
}
.account-wallet-detail .cn-copy-address .btn{
   padding: 0px;
   background: transparent;
   border: none;
   box-shadow: none;
   outline: none;
   display: flex;
   align-items: center;
   width: auto;
}
.account-wallet-detail .cn-copy-address .btn span{
   margin-left: 6px;
}
.account-wallet-detail .cn-copy-address .copybtn{
   font-size: 14px;
   font-weight: 400;
   color: rgb(251, 17, 142);
}
.account-wallet-detail .cn-copy-address .explorerbtn{
   font-size: 13.2px;
   font-weight: 400;
   margin-left: 16px;
   color: rgb(110, 114, 125);
   box-shadow: none;
   outline: none;
}
.account-wallet-detail .cn-copy-address .btn svg{
   font-size:16px;
}
.account-wallet-detail .cn-copy-address .btn:not(:disabled):not(.disabled):active{
   background-color: transparent;
   border: none;
   color: rgb(110, 114, 125);
   box-shadow: none;
   outline: none;
}
.account-footer .ft-data{
   margin: 0px;
}
.account-footer .ft-data p{
   margin-bottom: 0px;
   font-weight: 400;
   font-size: 16px;
   color: rgb(0, 0, 0);
}
.account-footer{
   justify-content: flex-start;
   padding:22px;
   background-color: rgb(237, 238, 242);
   border-bottom-left-radius: 20px;
   border-bottom-right-radius: 20px;
}
.marquee-slide {
   margin-top: 5px;
   margin-bottom: 10px;
   background-color: rgb(25, 27, 31);
   border: 2px solid rgba(178, 150, 78, 0.3);
}
.marquee-slide .overlay::before,
.marquee-slide .overlay::after{
   background-color: transparent;
   width: auto;
}
.marquee-slide ul{
   display: flex;
   align-items: center;
   margin: 0px;
   padding:3px 0px;
}
.marquee-slide ul li{
   list-style: none;
   color: #fff;
   font-size: 14px;
}
.marquee-slide ul li img{
   max-width: 30px;
}
.marquee-slide ul li span{
   font-size: 14px;
   font-weight: 500;
   margin-left: 2px;
}
@media(max-width:767.9px){
   .connect-wallet-button{
      font-size: 13px;
      padding:7px 11px;
      margin-right: 0px;
   }
}